<template>
  <div style="height: inherit">

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <div class="email-app-list ecommerce-application position-relative space-bottom">

      <!-- Barra pesquisa / ordenação -->
      <div
        class="app-fixed-search d-flex align-items-center"
      >

        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            :size="sizeIconsAction"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="searchTxt"
              value=""
              :placeholder="listview_txt_default.txt_search"
            />
          </b-input-group>
        </div>

        <div
          v-if="sortByOptions.length > 0"
          class="dropdown"
        >
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="p-0 mr-1"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                :size="sizeIconsAction"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="oField in sortByOptions"
              :key="`field-order-${oField.name}`"
              @click="listviewFilterOrderBy(oField.name)"
            >
              <feather-icon
                :icon="listviewIconOrderBy(oField.name)"
                :class="listviewIconOrderBy(oField.name, true)"
              />
              {{ oField.txt }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

      </div>

      <!-- Listings -->
      <div
        class="email-user-list scroll-area"
      >
        <vue-pull-refresh
          ref="listviewScroll"
          :on-refresh="listViewRefresh"
          :config="{
            errorLabel: $t('Problema ao atualizar'),
            startLabel: $t('Baixar para atualizar'),
            readyLabel: $t('Solte para atualizar'),
            loadingLabel: $t('A atualizar...'),
            pullDownHeight: 60,
          }"
          @scroll.native="listViewSavePositionScroll"
        >
          <div class="grid-view m-0 p-1">
            <component
              :is="blockListing"
              v-for="(listing, index) in listings"
              :key="`listing-${listing.idListing}`"
              ref="listing"
              :listing="listing"
              :index="index"
              :app-config="appConfig"
              :get-modal-rating-listing="getModalRatingListing"
              :user-auth="currentUser"
              :get-detail-listing="setShowListingQuickView"
            >
              <template slot="blockActions">
                <div class="item-options text-center">
                  <b-button
                    v-if="pageSel != 'listings-myShares' && pageSel != 'listings-shares' && pageSel != 'listings-shared' && pageSel != 'listings-shared-HUB'"
                    v-b-modal="`modal-options-listing`"
                    variant="primary"
                    tag="a"
                    class="btn-cart"
                    @click="setShowListingQuickView([true, listing])"
                  >
                    <span>{{ listing.pid }}</span>
                  </b-button>
                  <b-button
                    v-if="pageSel == 'listings-myShares' || pageSel == 'listings-shares' || pageSel == 'listings-shared' || pageSel == 'listings-shared-HUB'"
                    variant="primary"
                    tag="a"
                    class="btn-cart"
                    @click="setShowUserShareDetails([true, listing.idListing, listing.pid])"
                  >
                    <span>{{ listing.pid }}</span>
                  </b-button>
                  <b-button-group>
                    <b-button
                      v-if="appConfig.bUseEstudoMercadoAnalise === true"
                      variant="primary"
                      tag="a"
                      class="btn-cart"
                      @click.stop.prevent="openViewMarketResearch(listing.idListing)"
                    >
                      EM
                    </b-button>
                    <b-button
                      variant="primary"
                      tag="a"
                      class="btn-cart"
                      @click="setShowOptions([true, listing.idListing, listing.pid, listing])"
                    >
                      <feather-icon
                        icon="MoreVerticalIcon"
                        class="mr-50"
                      />
                    </b-button>
                  </b-button-group>
                </div>
              </template>
            </component>
          </div>
          <div
            class="no-results"
            :class="{'show': !listings.length}"
          >
            <h5 v-if="(totalFields === 0) && (totalListings === 0) && (listviewInit === 0)">
              {{ listview_txt_default.txt }}
            </h5>
            <h5 v-if="(totalFields > 0) && (totalListings === 0) && (listviewInit === 0)">
              {{ listview_txt_default.txt_not_find }}
            </h5>
            <h5 v-if="listviewInit === 1">
              {{ listview_txt_default.txt_loading }}
            </h5>
          </div>
        </vue-pull-refresh>
      </div>

      <b-container
        v-if="listings.length > 0"
        fluid
      >
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalListings"
              :per-page="perPage"
              class="mb-0 pagination-white pt-0_2 pb-0_2"
              :size="sizePaginationListviewResponsive"
            />
          </b-col>
        </b-row>
      </b-container>

      <b-overlay
        :show="$store.getters['listings/loadingListview']"
        no-wrap
      />

    </div>

    <!-- Share View/Detail -->
    <share-view
      :class="{'show': showUserShareDetails}"
      @close-share-view="setShowUserShareDetails([false, null, null])"
    />

    <options-right-sidebar
      v-if="showOptionsListing===true"
      :set-show-user-share-details="setShowUserShareDetails"
      :open-view-market-research="openViewMarketResearch"
    />

    <portal
      to="content-renderer-sidebar-left"
    >
      <listview-left-sidebar
        ref="leftSidebar"
        :class="{'show': mqShallShowLeftSidebar}"
        :show-msg-error-request="showMsgErrorRequest"
        :create-new="createNew"
        :create-new-pre="createNewPre"
        :set-show-user-share-details="setShowUserShareDetails"
        :open-left-sidebar="openLeftSidebar"
        :open-view-market-research="openViewMarketResearch"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

    <component
      :is="ModalRatingListing"
      v-if="showModalRating === true"
      ref="modalRatingListing"
      :rating="rating"
      :form-mode-view="true"
      :iso="countryApp"
    />

    <!-- Quick View -->
    <quick-view
      :class="{'show': showListingQuickView}"
      :get-modal-rating-listing="getModalRatingListing"
      :open-left-sidebar="openLeftSidebar"
      @close-quick-view="setShowListingQuickView([false, null])"
    />

  </div>

</template>

<script>
import store from '@/store'
import '@/libs/sweet-alerts'
import { onUnmounted } from '@vue/composition-api'
import {
  BFormInput, BInputGroup, BInputGroupPrepend,
  VBTooltip, BDropdown, BDropdownItem, BRow, BCol, BContainer, BPagination, BOverlay,
  BCard, BCardBody, BLink, BImg, BCardText, BButton, BButtonGroup, BMedia, BAvatar,
  VBModal, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import listingsModule from '@store-modules/listings/listview/index'
import shareModule from '@store-modules/listings/share/index'
import { mapGetters } from 'vuex'
import { Dialog } from '@capacitor/dialog'
import useCapacitor from '@core-custom/utils/capacitor'
import VuePullRefresh from 'vue-pull-refresh'
import ListviewLeftSidebar from './leftSidebar.vue'
import ShareView from '../share/listviewUsersShareListing.vue'
import QuickView from './quickView.vue'
import OptionsRightSidebar from './rightSidebar.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BContainer,
    BPagination,
    BOverlay,
    ListviewLeftSidebar,
    OptionsRightSidebar,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BButtonGroup,
    BMedia,
    BAvatar,
    BModal,
    VuePullRefresh,

    ShareView,
    QuickView,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      optionsListings: [],
      showModalRating: false,
      rating: {},
    }
  },
  computed: {
    ...mapGetters('listings', ['getLoadListview', 'listings', 'totalListings', 'perPage', 'searchTxt', 'sortByOptions', 'listviewOrderBy', 'listview_txt_default', 'totalFields', 'listviewInit',
      'pageSel', 'showOptionsListing', 'pid', 'showNewListing', 'totalListings', 'appConfig', 'selectedDataFiltersListview', 'positionListview', 'showListingQuickView']),
    ...mapGetters('share', ['showUserShareDetails']),
    ...mapGetters('auth', ['currentUser']),
    searchTxt: {
      get() { return this.$store.getters['listings/searchTxt'] },
      set(newValue) {
        this.$store.dispatch('listings/setSearchTxt', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    currentPage: {
      get() { return this.$store.getters['listings/currentPage'] },
      set(newValue) {
        this.$store.dispatch('listings/setCurrentPage', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    sizePaginationListviewResponsive() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? 'lg' : 'md')
    },
    sizeIconsAction() {
      const currentBreakPoint = this.$store.getters['app/currentBreakPoint']
      return ((currentBreakPoint === 'xs') ? '21' : '17')
    },
  },
  watch: {
    $route() {
      this.$refs.leftSidebar.$refs.filterScroll.$el.scrollTop = 0
    },
  },
  beforeDestroy() {
    this.$store.commit('listings/savePositionListview', this.sPostionListview)
    this.$store.commit('listings/saveStates')
  },
  async created() {
    try {
      this.$store.commit('listings/setHistoryKeyStorage', this.$route.name)
      await this.$store.dispatch('listings/initStates')
      await store.dispatch('listings/setActiveFetchListView', true)
      await store.dispatch('listings/fetchListView', { skipCurrentPage: true }).catch(error => {
        this.showMsgErrorRequest(error)
      })
      await store.dispatch('listings/getListview', { pageSel: this.$route.name }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$nextTick()

      if ((this.positionListview !== null) && (this.positionListview !== undefined)) {
        const { scrollTop, scrollLeft } = this.positionListview
        this.$refs.listviewScroll.$el.scrollTop = scrollTop
        this.$refs.listviewScroll.$el.scrollLeft = scrollLeft
      }
    } catch (err) {
      //
    }
  },
  mounted() {
    if (useCapacitor.isNative() === true) {
      document.body.classList.add('capacitor')
    }
  },
  destroyed() {
    if (useCapacitor.isNative() === true) {
      document.body.classList.remove('capacitor')
    }
  },
  methods: {
    listviewFilterOrderBy(field) {
      this.$store.dispatch('listings/listviewFilterOrderBy', field).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewIconOrderBy(filterField, renderClass) {
      let returnIcon = 'ArrowUpIcon'
      let returnClass = 'opacity-0'

      if (this.listviewOrderBy.column === filterField) {
        if (this.listviewOrderBy.sort === 'asc') {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowUpIcon'
        } else {
          returnClass = 'opacity-1'
          returnIcon = 'ArrowDownIcon'
        }
      }

      if (renderClass === true) {
        return returnClass
      }

      return returnIcon
    },
    async setShowUserShareDetails(newValue) {
      await this.openLeftSidebar(false)
      await this.setShowOptions([false, null, null])
      await this.$store.dispatch('share/setShowUserShareDetails', newValue).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async setShowListingQuickView(newValue) {
      await this.$store.dispatch('listings/setShowListingQuickView', newValue).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    setShowOptions(newValue) {
      this.$store.dispatch('listings/setShowOptions', newValue).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    createNew() {
      this.$router.push('/listings/new')
    },
    createNewPre() {
      this.$router.push('/listings/pre/new')
    },
    async showMsgErrorRequest(error) {
      if (useCapacitor.isNative() === true) {
        Dialog.alert({
          title: this.$t('Alerta'),
          message: error.message,
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('Alerta'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error.message,
          },
        })
      }
    },
    async getModalRatingListing(idListing, versionPending) {
      this.$root.$emit('app::loading', true)

      const formData = new FormData()

      formData.append('sw012s01', idListing)
      formData.append('versionPending', versionPending)

      await this.$store.dispatch('listings/previewModalRating', formData).then(async res => {
        this.rating = res.data.rating || {}
        this.$root.$emit('app::loading', false)

        this.showModalRating = true

        setTimeout(() => {
          this.$refs.modalRatingListing.showModal().then(async result => {
            await this.$refs.modalRatingListing.hideModal()
            this.showModalRating = false
            if (result === true) {
              setTimeout(() => {
                this.rating = {}
              }, 500)
            }
          })
        }, 500)
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        throw error
      })
    },
    openLeftSidebar(bOpen) {
      this.mqShallShowLeftSidebar = bOpen
    },
    listViewSavePositionScroll() {
      this.sPostionListview = {
        scrollTop: this.$refs.listviewScroll.$el.scrollTop,
        scrollLeft: this.$refs.listviewScroll.$el.scrollLeft,
      }
    },
    listViewRefresh() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('listings/pullToRefreshListview')
          .then(() => {
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },
    async openViewMarketResearch(idImovel) {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('listings/getMarketStudy', { id: idImovel }).then(async url => {
        this.$root.$emit('app::loading', false)

        if (url !== '') {
          useCapacitor.openUrlNewTab(url)
        }
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
  },
  setup() {
    const LISTINGS_MODULE_NAME = 'listings'
    const SHARE_MODULE_NAME = 'share'

    if (!store.hasModule(LISTINGS_MODULE_NAME)) {
      store.registerModule(LISTINGS_MODULE_NAME, listingsModule)

      onUnmounted(() => {
        if (store.hasModule(LISTINGS_MODULE_NAME)) store.unregisterModule(LISTINGS_MODULE_NAME)
      })
    }

    if (!store.hasModule(SHARE_MODULE_NAME)) {
      store.registerModule(SHARE_MODULE_NAME, shareModule)

      onUnmounted(() => {
        if (store.hasModule(SHARE_MODULE_NAME)) store.unregisterModule(SHARE_MODULE_NAME)
      })
    }

    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    const sPostionListview = {
      scrollTop: 0,
      scrollLeft: 0,
    }

    const countryApp = store.getters['auth/countryApp'].toUpperCase()

    const blockListing = () => import('@/views/modules/components/cardListing.vue')
    const ModalRatingListing = () => import(/* webpackChunkName: "listing-listview-modal-rating" */ /* webpackPrefetch: true */ '../form/form_data/modal/rating.vue')

    return {
      mqShallShowLeftSidebar,
      sPostionListview,
      countryApp,
      blockListing,
      ModalRatingListing,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.email-app-list.space-bottom{
  padding-bottom: 2.8rem;

  @include media-breakpoint-down(xs) {
    padding-bottom: 3rem;
  }
}

.email-app-list .email-user-list {
    position: relative!important;
    height: calc(100% - calc(3.49rem))!important;
 }

.ecommerce-application .search-product,
.ecommerce-application .input-group-text{
  height: inherit!important;
}

.capacitor{
  .email-user-list ::v-deep{
    .email-media-list li {
      animation:none!important;
      animation-fill-mode:none!important;
    }
  }
}

.pull-down-container ::v-deep{
  .pull-down-header{
    background-color:inherit!important;
  }
  .pull-down-content{
    font-family:inherit!important;
    color:inherit!important;
    font-size:inherit!important;
  }
}
</style>
